import React from 'react';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  
  container: {
    paddingTop: theme.spacing(4),
    flexGrow: 1,
  },

})); 

const PermissionDenied = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        <Box>
          <Typography component="h1" variant="h2">
            Permission Denied
          </Typography>
          <Typography variant="body1">
            Oops, it looks like you don't have permission to view this page. 
          </Typography>
        </Box>
      </Container>
    </div>
  );
}


export default PermissionDenied;
