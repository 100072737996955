

const api_key = 'ba12c1aa992f9c5b7db8a8cf3b115f75';

function get_trello_api(){
    
  // var userId = window.firebase.auth().currentUser.uid;
  // var token = window.db.collection("trello_integrations").doc(userId);
  // return token.get().then(function(doc) {
  //   var api_key = 'ba12c1aa992f9c5b7db8a8cf3b115f75';
  //   var trello_token = doc.data().trello_token;
  //   // var trello_token = localStorage.getItem("trello_token");
  //   var trello_member = doc.data().trello_member_id;
  //   var trello_api = {
  //     member : "5c7e6546f0e32d642464a6b4",
  //     key : api_key,
  //     token : trello_token
  //   };
  //   return trello_api;
  // });
}
function get_live_view(){
  var userId = window.firebase.auth().currentUser.uid;
  var token = window.db.collection("live-view").doc(userId);
  return token.get().then(function(doc) {
    var detail_level = doc.data().detail_level;
    var show_as_cards = doc.data().show_as_cards;
    var live_view = {
      detail_level : detail_level,
      show_as_cards : show_as_cards
    };
    return live_view;
  });
}
function status(response) {
  console.log(response, 'response');
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response)
  } else {
    return Promise.reject(new Error(response.status));
  }
}
function json(response) {
  return response.json()
}
export function trelloCall(url, method_type, errorMessage){
  return fetch(url, {method: method_type})
  .then(status)
  .then(json)
  .then(function(data) {  
    return data;
  }).catch(function(error) {
    console.log(error);
    var message = "Oops something went wrong. Please try again in a minute.";
    // errorMessageToast(message);
  });
}

// export function errorMessageToast(message) {
//   var elem = document.getElementById("error-snackbar");   
//   elem.innerText = message;
//   // Add the "show" class to DIV
//   elem.className = "show";

//   // After 3 seconds, remove the show class from DIV
//   setTimeout(function(){ elem.className = elem.className.replace("show", ""); }, 3000);
// }
export async function get_all_my_trello_boards(token){
  // return get_trello_api().then(function(trello_api){
    var url = "https://api.trello.com/1/members/me/boards?filter=open&fields=all&lists=all&memberships=none&organization=false&organization_fields=name%2CdisplayName&key="+ api_key + "&token=" + token;
    console.log(url, 'url');
    return trelloCall(url, 'get'); 
  // });
}
export function get_all_trello_boards(){
  return get_trello_api().then(function(trello_api){
    var url = "https://api.trello.com/1/members/"+trello_api.member+"/boards?filter=open&fields=all&lists=all&memberships=none&organization=false&organization_fields=name%2CdisplayName&key="+ trello_api.key + "&token=" + trello_api.token;
    return trelloCall(url, 'get'); 
  });
}
export async function get_board(submission, token) {
  var url = "https://api.trello.com/1/boards/" + submission.id + "?actions=all&boardStars=none&cards=none&card_pluginData=false&checklists=none&customFields=false&fields=name%2Cdesc%2CdescData%2Cclosed%2CidOrganization%2Cpinned%2Curl%2CshortUrl%2Cprefs%2ClabelNames&lists=open&members=none&memberships=all&membersInvited=none&membersInvited_fields=all&pluginData=false&organization=false&organization_pluginData=false&myPrefs=false&tags=false&key=" + api_key + "&token=" + token;
  return trelloCall(url, 'get');
}
function get_board_name(board_id) {
  return get_trello_api().then(function(trello_api){
    var url = "https://api.trello.com/1/boards/" + board_id + "?key=" + trello_api.key + "&token=" + trello_api.token;
    return trelloCall(url, 'get');
  });
}
function get_board_lists(board_id) {
  return get_trello_api().then(function(trello_api){
    var url = "https://api.trello.com/1/boards/" + board_id + "/lists?cards=open&card_fields=all&filter=open&fields=all&key=" + trello_api.key + "&token=" + trello_api.token;
    return trelloCall(url, 'get');
  });
}
function get_list_name(list_id) {
  return get_trello_api().then(function(trello_api){
    var url = "https://api.trello.com/1/lists/" + list_id + "?key=" + trello_api.key + "&token=" + trello_api.token;
    return trelloCall(url, 'get');
  });
}
export async function get_list_cards(submission, token){ 
  var url = "https://api.trello.com/1/lists/"+ submission.id +"/cards?fields=all&key=" + api_key + "&token=" + token;
  return trelloCall(url, 'get');
}
function all_boards_list(member, trello_api) {
  var url = "https://api.trello.com/1/members/" + member + "/boards?lists=none&key=" + trello_api.key + "&token=" + trello_api.token;
  return trelloCall(url, 'get');
}
function get_member(member, trello_api) {
  var url = "https://api.trello.com/1/members/"+member+"?fields=all&key=" + trello_api.key + "&token=" + trello_api.token;
  return trelloCall(url, 'get');
}
function check_token(trello_api) {
  var url = "https://api.trello.com/1/tokens/" + trello_api.token + "?key=" + trello_api.key + "&token=" + trello_api.token;
  return trelloCall(url, 'get');
}
export async function get_card_with_extras(submission, token){
  var url = "https://api.trello.com/1/cards/" + submission.id + "?fields=all&attachments=true&attachment_fields=all&members=false&membersVoted=false&checkItemStates=false&checklists=all&checklist_fields=all&board=false&list=false&pluginData=false&stickers=false&sticker_fields=all&customFieldItems=false&key=" + api_key + "&token=" + token;
  return trelloCall(url, 'get');
}
function createBoard(submission){
  return get_trello_api().then(function(trello_api){
    var url = "https://api.trello.com/1/boards/?name=" + submission.board_name + "&defaultLists=false&key=" + trello_api.key + "&token=" + trello_api.token;
    return trelloCall(url, 'post');
  });
}
export function editBoardTitle(submission, token){
  var url = "https://api.trello.com/1/boards/" + submission.id + "?name=" + submission.name + "&key=" + api_key + "&token=" + token;
  return trelloCall(url, 'put');
}
export function updateBoardDescription(submission, token){
  var url = "https://api.trello.com/1/boards/" + submission.id + "?desc=" + submission.name + "&key=" + api_key + "&token=" + token;
  return trelloCall(url, 'put');
}
function createList(submission){
  return get_trello_api().then(function(trello_api){
    var url = "https://api.trello.com/1/boards/"+submission.board_id+"/lists?name=" + submission.list_name + "&pos=bottom&key=" + trello_api.key + "&token=" + trello_api.token;
    return trelloCall(url, 'post');
  });
}
export function updateListName(submission, token){
  var url = "https://api.trello.com/1/lists/" + submission.id + "/name?value="+ submission.name + "&key=" + api_key + "&token=" + token;
  return trelloCall(url, 'put');
}
function create_card(submission){
  return get_trello_api().then(function(trello_api){
    var url = "https://api.trello.com/1/cards/?pos=" + submission.pos + "&idList=" + submission.listId + "&key=" + trello_api.key + "&token=" + trello_api.token;
    return trelloCall(url, 'post');
  });
}
export async function updateCard(submission, token) {
  var url = "https://api.trello.com/1/cards/" + submission.id + "?" + submission.level + "=" + submission.name + "&key=" + api_key + "&token=" + token;
  return trelloCall(url, 'put');
}
function closeTicket(card){
  return get_trello_api().then(function(trello_api){
   var url = "https://api.trello.com/1/cards/" + card.id + "?closed=true&key=" + trello_api.key + "&token=" + trello_api.token;
   return trelloCall(url, 'put');
  });
}
function openTicket(card){
  return get_trello_api().then(function(trello_api){
   var url = "https://api.trello.com/1/cards/" + card.id + "?closed=false&key=" + trello_api.key + "&token=" + trello_api.token;
   return trelloCall(url, 'put');
  });
}
function jira_toggle_checklist(data, trello_api){
  var url = "https://api.trello.com/1/cards/"+data.card_id+"/checkItem/"+data.check_id+"?state="+data.state+"&key=" + trello_api.key + "&token=" + trello_api.token;
  return trelloCall(url, 'put');
}
function trello_update_checkitem(checkitem, trello_api){
  var url = "https://api.trello.com/1/cards/"+checkitem.card_id+"/checkItem/"+checkitem.id+"?name="+checkitem.name+"&key=" + trello_api.key + "&token=" + trello_api.token;
  return trelloCall(url, 'put'); 
}
function trello_remove_checkitem(checkitem, trello_api){
  var url = "https://api.trello.com/1/cards/"+checkitem.card_id+"/checkItem/"+checkitem.id+"?key=" + trello_api.key + "&token=" + trello_api.token;
  return trelloCall(url, 'delete'); 
}