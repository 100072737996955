import React from 'react';

import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { FirebaseContext } from '../Firebase';
import Main from './main';


const Home = () => (
  <FirebaseContext.Consumer>
    {firebase => {
      return (
        <Main />
      );
    }}
  </FirebaseContext.Consumer>
);
 
const condition = authUser => !!authUser;
 
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Home);