import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from './input';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(12),
  }
}));

const MultiLine = (props) => {
  const classes = useStyles();
  return(
    <React.Fragment>
      <Grid className={classes.container} container>
        <Grid item xs={12}>
          <Input field={props.field} /> 
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default MultiLine;