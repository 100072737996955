import React, {useEffect, useState} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import {withFirebase} from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import TrelloAuth from '../Trello';
import TrelloClient, { Trello } from 'react-trello-client';
import {trelloCall, get_all_trello_boards} from '../Trello/calls';
import Navigation from '../AppBar';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(9),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Settings = (props) => {
  const classes = useStyles();  
  const user = JSON.parse(localStorage.getItem('authUser'));
  const [details, setDetails] =useState(user);

  useEffect(() => {
    console.log(details, 'dets');

  }, []);

  return (
    <React.Fragment>
      <Navigation />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container>
            <Grid container direction="column" alignItems="stretch" item xs={12}>
            <Paper className={classes.paper} square elevation={1}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                <Typography variant="h3">
                  Settings
                </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h4">Your details</Typography>
                  <Typography variant="body1">{details.name.firstName} {details.name.lastName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h4">Subscription</Typography>
                  <Typography variant="body1">{capitalizeFirstLetter(details.subscription.plan)}</Typography>
                  <Typography variant="body1">Subscription expiry date {details.subscription.trailEndDate}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4">Trello Integration</Typography>
                  {details.trello === false ? 
                    <React.Fragment>
                      <Typography variant="body1">No Trello integration has been set up. Click on the button below to log in and connect your trello account.</Typography>
                      <TrelloAuth refresh={details.trello} />
                    </React.Fragment>
                  :
                    <React.Fragment>
                      <Typography variant="body1">Set up</Typography>
                      <TrelloAuth refresh={details.trello} />
                    </React.Fragment>
                  }
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4">Jira Integration</Typography>
                  <Typography variant="body1">Coming soon...</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
            
        </Grid>
      </Container>
    </React.Fragment>


  );
}


const condition = authUser =>
  authUser;
  // && !!authUser.roles[ROLES.ADMIN];
 
export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Settings);