import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
 
}));

const Header = (props) => {

  const classes = useStyles();
  console.log(props.title);

  return (
    <Paper className={classes.paper} square elevation={1}>
      <Typography component="h2" variant="h5">
        {props.title.map((level) => {
          return (
          <React.Fragment>
            {level}
            </React.Fragment>
          )
        })}
      </Typography> 
    </Paper>
  );
}

export default Header;