import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Page/header';
import Body from '../Page/body';
import { Grid } from '@material-ui/core';
import { get_all_my_trello_boards } from '../Trello/calls';

const useStyles = makeStyles((theme) => ({
  headerGrid: {
    marginTop: theme.spacing(6),
  },
 
}));

const Single = (props) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('authUser'));
  const [title, setTitle] = useState(['Loading...']);
  const [page, setPage] = useState({});
  console.log(props);
  useEffect(()=>{
    var data = {};
    if(props.view.level === "root"){
      
      setTitle(['All Trello Boards', 'another']);
        if(props.trelloExists){
          get_all_my_trello_boards(user.trello_creds.token).then(result=>{
            Object.values(result).map((board)=>{
              if(board.closed !== true){
                data[board.id] = {
                  name: board.name,
                  id : board.id,
                  desc: board.desc,
                  level : 'board',
                }
              }
              //old
              board.level = 'board';
              // var listItems = {};
              // Object.values(board.lists).map((list)=>{
              //   if(list.closed !== true){
              //     listItems[list.id] = {
              //       name: list.name,
              //       id : list.id,
              //       closed: list.closed,
              //       level : 'list',
              //     }
              //   }
              //   list.level = 'list';
              // });
              // data[board.id].items = listItems;
              data[board.id].items = {};
            });
            setPage(data);
          }).catch((error)=>{
            console.log(error, 'error');
          });
        }
      } else {

    }
    // get_all_my_trello_boards(user.trello_creds.token).then(result=>{
    //   // setBody(result);
    //   console.log(result);
    // }).catch((error)=>{
    //   console.log(error, 'error');
    // });
    // var url = "https://api.trello.com/1/members/me?&key="+ "ba12c1aa992f9c5b7db8a8cf3b115f75" + "&token=" + "c0f11db08a9e7d5d79391f69ea58a1ca0ab2235bb01a89ed1214a92e0a7d0826";
    // get_all_trello_boards().then(result => {
    //   console.log(result, 'done');
    // });
      // var paras = {
      //   filter: 'open',
      //   token: 'c0f11db08a9e7d5d79391f69ea58a1ca0ab2235bb01a89ed1214a92e0a7d0826',
      // }
      // // Trello.rest('GET','members/me/boards').then(me =>{
      // //   console.log(me, 'its me');
      // // }).catch(error => {
      // //   console.log(error);
      // // });
      // fetch("https://api.trello.com/1/members/me?key=ba12c1aa992f9c5b7db8a8cf3b115f75&token="+paras.token)
      // .then(status)
      // .then(json)
      // .then(result=>{
      //   console.log(result);
      // })

  }, []);

  return (
    <React.Fragment>
      <Grid container>
        <Grid className={classes.headerGrid} item xs={12}>
        <Header title={title} />
        </Grid>
        <Body trelloExists={props.trelloExists} setPage={setPage} page={page} />
      </Grid>
      
    </React.Fragment>
  )
}
export default Single;