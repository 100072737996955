import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Actions from './actions';
import Input from './input';
import MultiLine from './multiLine';

import {
  get_board,
  get_list_cards,
  get_card_with_extras
} from '../../Trello/calls';

const useStyles = makeStyles((theme) => ({
  container: {
    borderLeft: '3px solid #fff',
    paddingLeft: theme.spacing(4),
    "&:hover": {
      borderLeft: "3px solid #5F2D91",
    }
  },

}));



const SingleLine = (props) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('authUser'));
  const [showChildren, setShowChildren] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const [hasDescription, setHasDescription] = useState(false);
  const [description, setDescription] = useState({name: "Loading...", level : "", id: "", multiLine:true})
  const [item, setItem]  = useState({id:0, name: 'Loading...', items:{}});
  
  useEffect(()=>{
    if(props.field){
      setItem(props.field);
    }
  }, [props.field.items]);

  useEffect(()=>{
    if(!showChildren) {
      setHasChildren(false);
      setHasDescription(false);
    }
    if(showChildren){
      if(props.field.level === 'board'){
        get_board(props.field, user.trello_creds.token).then(result => {
          if(result.desc !== ""){
          let data = {
            name : result.desc,
            level: 'board-desc',
            id: result.id,
            multiLine: true,
          };
          setDescription(data);
          setHasDescription(true);
        }
          var listItems = props.field;
          if(Object.entries(result.lists).length !== 0){
            Object.values(result.lists).map((list)=>{
              if(list.closed !== true){
                listItems.items[list.id] = {
                  name: list.name,
                  id : list.id,
                  closed: list.closed,
                  items: {},
                  level : 'list',
                }
              }
              list.level = 'list';
            });
            setHasChildren(true);
          } else {
            console.log('no lists');
          }
          setItem(listItems);

        });
        
      }
      if(props.field.level === 'list'){
        let data = props.field;
        get_list_cards(props.field, user.trello_creds.token).then(results => {
        
          if(results.length !== 0){
            Object.values(results).map((result)=>{
              if(result.closed !== true){
                data.items[result.id] = {
                  name : result.name,
                  id: result.id,
                  level: 'card',
                }
              }
            });
            setHasChildren(true);
          } else {
            console.log('no cards');
          }
          setItem(data);
        });
      } 
      if(props.field.level === 'card'){
        get_card_with_extras(props.field, user.trello_creds.token).then(result => {
          let data = {
            name : result.desc,
            level: 'card-desc',
            id: result.id,
            multiLine: true,
          };
          setDescription(data);
          setHasDescription(true);
        });
      }
    }
  }, [showChildren, setItem] );

  return (
    <React.Fragment key={item.id}>
      <Grid className={classes.container} container spacing={0}justify="flex-start" alignItems="stretch">
        <Grid item>
          <Actions showChildren={showChildren} setShowChildren={setShowChildren} />
        </Grid>
        <Grid item xs={10}>
          <Input field={item} />
        </Grid>
        {hasDescription && showChildren ?
          <MultiLine field={description} /> 
        : ""
        }
        {hasChildren && Object.values(item.items).map((value) => (
          <SingleLine key={value.id} field={value} />
        ))}
        {item.level === "card" ?
        <Grid item xs={12}>
                <ButtonBase
          focusRipple
          // className={classes.image}
          // focusVisibleClassName={classes.focusVisible}
          // style={{
          //   width: image.width,
          // }}
        >
          Add
        </ButtonBase>
        </Grid>
        : ""}
      </Grid>
    </React.Fragment>
  )
}

export default SingleLine;