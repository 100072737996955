import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomePage from '../Home'; 
import SignInPage from '../SignIn';
import SignUpPage from '../SignUp';
import SettingsPage from '../Settings';
import PermissionDenied from '../PermissionDenied';
import { withAuthentication } from '../Session';
import * as ROUTES from '../../constants/routes';


  
const useStyles = makeStyles((theme) => ({
  base: {
   backgroundColor: '#f4f5f7',
   height: '100%',
  },
}));

const App = () => {
  const classes = useStyles();

return (
  <Router>
    <div className={classes.base}>
      <Route exact path={ROUTES.HOME} component={HomePage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SETTINGS} component={SettingsPage} />
      <Route path={ROUTES.PERMISSIONDENIED} component={PermissionDenied} />
    </div>
  </Router>
);
}
 
export default withAuthentication(App);