import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Icon from '@material-ui/core/Icon';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import logo from '../../assets/logo.svg';
import { Button } from '@material-ui/core';
import SignOutButton from '../SignOut';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    
  },
  topbar: {
    backgroundColor: '#fff',
  },
  logo: {
    marginRight: theme.spacing(2),
    float: 'left',
  },
  title: {
    
    float: 'left',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    color: '#5F2D91',
    
  },
  logoTitle: {
    padding: theme.spacing(1),
  },
  views: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  rightLinks: {
    textAlign: "right",
  },
}));


const Navigation = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.topbar} position="fixed">
        <Toolbar variant="dense">
          <Grid container>
            <Grid item xs className={classes.logoTitle}>
        <Link href={ROUTES.HOME}>
        <Icon className={classes.logo} ><img alt="Jotterbox" src={logo} height={25} width={25}/></Icon>
          <Typography className={classes.title} component="h1" variant="h5" noWrap>
            Jotterbox
          </Typography>
          </Link>
          </Grid>
          {props.format !== undefined ?
          
            <Grid item xs className={classes.views}>
              {props.format === 'single' ?
              <React.Fragment>
                <Button size="small" color="primary" variant="contained" onClick={()=>{props.toggleView()}} >
                <ViewHeadlineIcon />
                </Button>
                <Button size="small"  variant="outlined" onClick={()=>{props.toggleView()}}>
                  <VerticalSplitIcon />
                </Button>
              </React.Fragment>
            :
            <React.Fragment>
              <Button size="small"  variant="outlined" onClick={()=>{props.toggleView()}}>
              <ViewHeadlineIcon />
              </Button>
              <Button size="small" color="primary" variant="contained" onClick={()=>{props.toggleView()}} >
              <VerticalSplitIcon />
              </Button>
            </React.Fragment>
            }
            </Grid>
          : 
          <Grid item>

          </Grid>
          }
          <Grid item className={classes.rightLinks} xs>
          
          <AuthUserContext.Consumer>
            {authUser =>
              authUser ? (
              <React.Fragment>
                <IconButton href={ROUTES.SETTINGS} edge="end"><AccountCircleIcon /></IconButton>
                <LoginAuth authUser={authUser} />
              </React.Fragment>
              ) : (
              <LoginNonAuth />
              )
            }
          </AuthUserContext.Consumer>
          </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
const LoginAuth = ({ authUser }) => (
  <SignOutButton />
);

const LoginNonAuth = () => (
<Button type="button"  href={ROUTES.SIGN_IN}>
Login
</Button>
);

export default Navigation;