import React, {useEffect, useState} from 'react';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import SingleLine from '../EditFields/singleLine';


const useStyles = makeStyles((theme) => ({
  container: {
    // borderLeft: '3px solid #fff',
    paddingLeft: theme.spacing(0),
    // "&:hover": {
    //   borderLeft: "3px solid #5F2D91",
    // }
  },

}));

const BlockWrapper = (props) => {
  const classes = useStyles();
  
  return (
    <React.Fragment>
      {Object.values(props.page).map((block)=>(
        <Container className={classes.container} key={block.id}>
          <SingleLine setPage={props.setPage} field={block} />
        </Container>
    ))}
    </React.Fragment>
  );
}

export default BlockWrapper;
