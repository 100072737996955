export const HOME = '/';
// export const PRICING = '/#pricing';
// export const CONTACT = '/#contact';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';
export const SIGN_UP = '/signup';
export const SETTINGS = '/settings';
// export const SUBSCRIBE = '/subscribe';
// export const THANKYOU = '/thankyou';
// export const LEGAL = '/legal';
// export const PRIVACY = '/legal/privacy';
// export const COOKIES = '/legal/cookies';
// export const ENDUSERAGREEMENT = '/legal/enduseragreement';
// export const DATAPROTECTION = '/legal/dataprocessingagreement';
// export const TANDC = '/legal/terms';
export const PERMISSIONDENIED = '/403';
