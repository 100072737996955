import React , {useState} from 'react';
import { compose } from 'recompose';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import AppBar from '../AppBar';

import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));





const SignIn = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const Toast = () => {
    return(
      <div>
      <Collapse in={checked} collapsedHeight={0}>
      <Alert severity="error">
        <strong>Error - </strong>{errorMessage}
      </Alert>
    </Collapse>
    </div>
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.firebase.doSignInWithEmailAndPassword(email, password).then(() => {
      props.history.push(ROUTES.HOME);
    })
    .catch(error => {
      setErrorMessage(error.message); 
      setChecked(true);
    });
  }

  function microsoftSSO(){
    // event.preventDefault();
    // var provider = new props.firebase.auth.OAuthProvider('microsoft.com');
    props.firebase.doSignInWithMicrosoft()
    .then(function(result) {
      console.log(result, 'result');
    // User is signed in.
    // IdP data available in result.additionalUserInfo.profile.
    // OAuth access token can also be retrieved:
    // result.credential.accessToken
    // OAuth ID token can also be retrieved:
    // result.credential.idToken
  })
  .catch(function(error) {
    // Handle error.
  });
  }
  
  return (
    <React.Fragment>
    <AppBar />
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Toast />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onInput={ e=>setEmail(e.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onInput={ e=>setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Button 
            fullWidth
            onClick={()=>microsoftSSO()}
          >Sign in with Microsoft</Button>
          <Grid container>
            <Grid item xs>
              <Link href={ROUTES.PASSWORD_FORGET} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href={ROUTES.SIGN_UP} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
              
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
    </React.Fragment>
  );
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignIn);

export default (SignInForm);