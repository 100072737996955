import React, {useState} from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  expand: {
    color: '#5F2D91',
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  focus:{
    color: '#5F2D91',
  }
}));

const Actions = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleFocusClick= () => {
    console.log(props);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
    props.setShowChildren(!props.showChildren);
  };
  return (
    <React.Fragment>
    <IconButton size="small" aria-label="focus on item" onClick={handleFocusClick}>
      <ZoomOutMapIcon className={classes.focus} />
    </IconButton>
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      onClick={handleExpandClick}
      aria-expanded={expanded}
      aria-label="show more"
      size="small" 
    >
      <ExpandMoreIcon />
    </IconButton>
  </React.Fragment>
  )
}

export default Actions;