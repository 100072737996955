import app from 'firebase/app';
// import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';


const config = {
  apiKey: "AIzaSyArtAJdOSw7fs0Zj5QLdg3Q69diGItgRL4",
  authDomain: "jotterbox-trello.firebaseapp.com",
  databaseURL: "https://jotterbox-trello.firebaseio.com",
  projectId: "jotterbox-trello",
  storageBucket: "jotterbox-trello.appspot.com",
  messagingSenderId: "827037262019",
  appId: "1:827037262019:web:c7400ca957347a9c2b0276",
  measurementId: "G-83WZTTEZ78"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();

  }



  // *** AUth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
 
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);
  
  doSendEmailVerification = () =>
  this.auth.currentUser.sendEmailVerification({
    url: "https://jotterbox-app.web.app",
  });

  // *** custom user management *** //
  doCreateUserWithEmail = email =>
    this.auth.createUser(email);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
  this.auth.onAuthStateChanged(authUser => {
    if (authUser) {
      this.user(authUser.uid)
        .get()
        .then(snapshot => {
          const dbUser = snapshot.data();
          // default empty roles
          if (!dbUser.roles) {
            dbUser.roles = {};
          }

          // merge auth and db user
          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...dbUser,
          };

          next(authUser);
        });
    } else {
      fallback();
    }
  });

  currentUserId = (uid) => 
  this.auth.onAuthStateChanged(authUser => {
    if (authUser){
      return authUser.uid;
    }
  });

  // *** User API ***

  user = uid => this.db.collection('users').doc(`${uid}`);
  users = () => this.db.collection('users');
  userByEmail = email => this.db.collection('users').where('email', "==", email);

  //** User Tokens ***/
  userUpdateTrelloToken = (uid, token, user, set) => {
    const batch = this.db.batch();  
    const connected = this.db.doc(`users/${uid}`);
    batch.update(connected, {trello:true});
    const trello = this.db.doc(`users/${uid}`);
    batch.update(trello, {trello_creds : {token, date: new Date()}});
    batch.commit().then(()=>{
      return true;
    }).catch((error)=>{
      console.log(error, 'error');
    });
  }
  //***Last View */
  lastView = (uid) => this.db.doc(`lastView/${uid}`);

// *** Domains *** //
checkDomain = domain => this.db.doc(`domains/${domain}`);

saveDomain = domain => this.db.doc(`domains/${domain}`);
companies = () => this.db.collection('company');
locations = () => this.db.collection('location');
groups = cid => this.db.collection(`company/${cid}/groups`);
 company = cid => this.db.doc(`company/${cid}`);
 location = lid => this.db.doc(`location/${lid}`);
 group = (cid, gid) => this.db.collection(`company/${cid}/groups`).doc(`${gid}`);
 locationPerson = (lid, uid) => this.db.doc(`location/${lid}`).collection('people').doc(`${uid}`);
  // *** Company API ***

  newPerson = lid => this.db.doc().child(`location/${lid}/people`);
  
  //*** attempted cloud function ****/

  inviteUser = data => this.functions.httpsCallable('UserInvitation');
}


 
export default Firebase;