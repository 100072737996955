import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid, Container } from '@material-ui/core';
import * as ROUTES from '../../constants/routes';
import BlockWrapper from './Trello/BlockWrapper';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2, 2, 2, 0),
    maxHeight: '70vh',
    overflow: 'scroll',
  },
 
}));




const Body = (props) => {

  const classes = useStyles();
  
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
    <Paper className={classes.paper} square elevation={1}>
    {props.trelloExists === false ? <Button href={ROUTES.SETTINGS}>Set up integration</Button> :
      <BlockWrapper setPage={props.setPage} page={props.page} />
    }
    </Paper>
    </Grid>
    </Grid>
  );
}

export default Body;