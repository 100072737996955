import React,{useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { withFirebase } from '../../Firebase';
import { 
  updateListName, 
  editBoardTitle,
  updateBoardDescription,
  updateCard
} from '../../Trello/calls';

const useStyles = makeStyles((theme) => ({
  top: {
    height: 'auto',
  },
  board:{
    fontSize:'larger',
  },
  list:{
    fontSize:'large',
  },
  desc: {
    fontSize: 'smaller',
  }

}));



const Input = (props) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('authUser'));
  const [name, setName] = useState(props.field.name);
  const [error, setError] = useState(false);
  const [variant, setVariant] = useState({});
  const [multiLine, setMultiLine] = useState(false);
  const [helperText, setHelperText] = useState('');

  // const styling = {disableUnderline: true, classes: {input: classes.board,}}
  useEffect(()=>{
    if(props.field.level === 'board'){
      setVariant({disableUnderline: true, height: 'auto', classes: {input: classes.board,}});
    } 
    if(props.field.level === 'list') {
      setVariant({disableUnderline: true, classes: {input: classes.list,}});
    } 
    if (props.field.level === 'card') {
      setVariant({disableUnderline: true, classes: {input: classes.list,}});
    }
    else if (props.field.level === 'card-desc' || props.field.level === 'board-desc') {
      setVariant({disableUnderline: true, classes: {input: classes.desc,}});
    }
    if(props.field.multiLine){
      setMultiLine(props.field.multiLine);
    }
  }, [props.field.level])

  function saveValue(value, field){
    var submission = {id : field.id, name: value}
    if(submission.name.replace(/\n/g,"%0A").replace(/#/g, '%23').length > 10000){
      setError(true);
      var length = name.replace(/\n/g,"%0A").replace(/#/g, '%23').length;
      setHelperText(length + '/10000');
      alert('WARNING: Autosave NOT completed. You have exceeded the maximum number of characters by ' + `${length - 10000}` + '. Please remove some characters and try again.');
    } else {
      setError(false);
      setHelperText("");
      if(field.level === 'board'){
        editBoardTitle(submission, user.trello_creds.token);
      }
      if(field.level === 'board-desc'){
        submission.name = submission.name.replace(/\n/g,"%0A").replace(/#/g, '%23');
        updateBoardDescription(submission, user.trello_creds.token);
      }
      if(field.level === 'list'){
        updateListName(submission, user.trello_creds.token);
      }
      if(field.level === 'card'){
        submission.level = 'name';
        updateCard(submission, user.trello_creds.token);
      }
      if(field.level === 'card-desc'){
        submission.level = 'desc';
        submission.name = submission.name.replace(/\n/g,"%0A").replace(/#/g, '%23');
        console.log(submission, 'submission');
        updateCard(submission, user.trello_creds.token);
      }
    }
  }


  return (
  <form noValidate autoComplete="off">
    <TextField 
      className={classes.top}
      id="standard-basic" 
      fullWidth
      value={name}
      onInput={ e=>{setName(e.target.value);}}
      onBlur={ e=>{saveValue(e.target.value, props.field);}}
      helperText={helperText}
      InputProps={
        variant
      }
      error={error}
      multiline={multiLine}
    />
  </form>
    );
}

export default withFirebase(Input);