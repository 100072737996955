import React, {useEffect, useState} from 'react'
import TrelloClient from 'react-trello-client'

import {withFirebase} from '../Firebase';

const TrelloAuth = (props) => {

  const [buttonText, setButtonText] = useState("Log in with Trello");
  
  useEffect(()=>{
    console.log(props);
    if(props.refresh === true){
      setButtonText('Refresh Trello Integration');
    }
  },[]);
    
  async function updateUser(){
    const token = localStorage.getItem('trello_token');
    const user = JSON.parse(localStorage.getItem('authUser'));
    await props.firebase.userUpdateTrelloToken(user.uid, token);
    setButtonText('Refresh Trello Integration');
  
  }

    return(
        <TrelloClient
            apiKey="ba12c1aa992f9c5b7db8a8cf3b115f75" // Get the API key from https://trello.com/app-key/
            clientVersion={1} // number: {1}, {2}, {3}
            apiEndpoint="https://api.trello.com" // string: "https://api.trello.com"
            authEndpoint="https://trello.com" // string: "https://trello.com"
            intentEndpoint="https://trello.com" // string: "https://trello.com"
            authorizeName="Jotterbox for Trello" // string: "React Trello Client"
            authorizeType="popup" // string: popup | redirect
            authorizePersist={true}
            authorizeInteractive={true}
            authorizeScopeRead={false} // boolean: {true} | {false}
            authorizeScopeWrite={true} // boolean: {true} | {false}
            authorizeScopeAccount={true} // boolean: {true} | {false}
            authorizeExpiration="never" // string: "1hour", "1day", "30days" | "never"
            authorizeOnSuccess={() => updateUser(setButtonText)} // function: {() => console.log('Login successful!')}
            authorizeOnError={() => console.log('Login error!')} // function: {() => console.log('Login error!')}
            autoAuthorize={false} // boolean: {true} | {false}
            authorizeButton={true} // boolean: {true} | {false}
            buttonStyle="metamorph" // string: "metamorph" | "flat"
            buttonColor="green" // string: "green" | "grayish-blue" | "light"
            buttonText={buttonText} // string: "Login with Trello"
        />
    )
}
 
export default withFirebase(TrelloAuth);