import React, {useState} from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import firebase from 'firebase/app';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles'; 


import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import * as EmailValidator from 'email-validator';  
import Navigation from '../AppBar';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



const SignUpFormBase = (props) => {
  const classes = useStyles();
  const roles = {};
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameHelperText, setLastNameHelperText] = useState("");
  const [companyNameError, setCompanyNameError] = useState(false);
  const [companyNameHelperText, setCompanyNameHelperText] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [allowExtraEmails, setAllowExtraEmails] = useState(false);
  const [checked, setChecked] = useState(false);
  const [termsAndPrivacy, setTermsAndPrivacy] = useState(false);
  const [termsAndPrivacyError, setTermsAndPrivacyError] = useState(false);
  const [termsAndPrivacyHelp, setTermsAndPrivacyHelp] = useState('');

  const Toast = () => {
    return(
      <div>
      <Collapse in={checked} collapsedHeight={0}>
      <Alert severity="error">
        <strong>Error - </strong>{errorMessage}
      </Alert>
    </Collapse>
    </div>
    );
  }

  async function signUpUser() {
    var date = new Date();
    var trialEndDate = new Date();
    trialEndDate.setDate(trialEndDate.getDate() + 7);
    //create a new authenticated user and return the uid
    const userId = await props.firebase.doCreateUserWithEmailAndPassword(email, password)
    .then(authUser => {           

      var newUser = firebase.auth().currentUser;
      newUser.updateProfile({
        displayName: firstName + " " + lastName
      });
      return authUser.user.uid;
    });
    return props.firebase.user(userId).set({
      name:{
        firstName,
        lastName,
      },
      email,
      roles,
      trello : false,
      creationDate: date,
      termsAgreed: date,
      subscription: {
        plan: 'trial',
        planStartDate: date,
        trialEndDate,
      },
      marketing : {
          optIn : allowExtraEmails,
      },
    }, 
    { merge:true });
  }

  function handleSubmit(event) {
    event.preventDefault();
    roles[ROLES.ADMIN] = ROLES.ADMIN;
    //validate form
    var valid = true;
    if(firstName === "") {
      setFirstNameError(true);
      setFirstNameHelperText('required');
      valid = false;
    }
    if(lastName === "") {
      setLastNameError(true);
      setLastNameHelperText('required');
      valid = false;
    }
    if(companyName === "") {
      setCompanyNameError(true);
      setCompanyNameHelperText('required');
      valid = false;
    }

    var emailValid = EmailValidator.validate(email); // true
    if(emailValid === false) {
      setEmailError(true);
      setEmailHelperText("You must enter a vaild email address");
      valid = false;
    }
    if(password !== password2){
      setError(true);
      setHelperText('Passwords must be identical');
      valid = false;
    }
    
    if(termsAndPrivacy === false){
      setTermsAndPrivacyError(true);
      setTermsAndPrivacyHelp('You must accept our General Subscription Terms & Conditions, End User License and Privacy Policy to sign up to our service');
      valid = false;
    }
    if(valid === true){
      // var domain = email.split("@");
      // domain = domain[1].replace(/\./g, '%2E');
      //check if domain has already signed up
      // var domainCheck = {};
      // var t = props.firebase.checkDomain(domain)
      // t.get().then(function(result){
      //   domainCheck = result.exists; 
      //   var present = [
      //     'gmail%2Ecom', 
      //     'yahoo%2Eco%2Euk', 
      //     'yahoo%2Ecom',
      //     'googlemail%2Ecom',
      //     'hotmail%2Ecom',
      //     'hotmail%2Eco%2Euk',
      //     'aol%2Ecom',
      //     'outlook%2Ecom'].includes(domain);

        // if (domainCheck === false || present === true) {
          signUpUser().then(() => {
            return props.firebase.doSendEmailVerification();
          })
          .then(() => {
            props.history.push(ROUTES.HOME);
          })
          .catch(error => {
            console.log(error);
            setErrorMessage(error.message); 
            setChecked((prev) => !prev);
          });
        // } else if (domainCheck === true){
        //   setErrorMessage('Oops, it looks like someone from your organisation has already signed up.'); 
        //   setChecked((prev) => !prev);
        // }
      // });
    }

  
  }

  const toggleOptIn = (event) => {
    setAllowExtraEmails(event.target.checked);
  }
  const toggleTerms = (event) => {
    setTermsAndPrivacy(event.target.checked);
    setTermsAndPrivacyError(false);
    setTermsAndPrivacyHelp("");
  }

  return (
    <React.Fragment>
      <Navigation />
    <Container component="main" maxWidth="xs">

      <CssBaseline />
      <div className={classes.paper}>
      <Toast />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                error={firstNameError}
                helperText={firstNameHelperText}
                id="firstName"
                label="First Name"
                value={firstName}
                onInput={ e=>{setFirstName(e.target.value); setFirstNameError(false); setFirstNameHelperText("");}}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                error={lastNameError}
                helperText={lastNameHelperText}
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onInput={ e=>{setLastName(e.target.value); setLastNameError(false); setLastNameHelperText("");}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                error={companyNameError}
                helperText={companyNameHelperText}
                id="companyName"
                label="Company Name"
                name="companyName"
                value={companyName}
                onInput={ e=>{setCompanyName(e.target.value); setCompanyNameError(false); setCompanyNameHelperText("")}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Company Email Address"
                name="email"
                value={email}
                onInput={ e=>{setEmail(e.target.value); setEmailError(false); setEmailHelperText("");}}
                error={emailError}
                helperText={emailHelperText}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onInput={ e=>setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                error= {error}
                name="password2"
                label="Confirm Password"
                type="password"
                id="password2"
                value={password2}
                helperText={helperText}
                onInput={e => {setPassword2(e.target.value); setError(false); setHelperText("");}}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
              <FormLabel component="legend">Marketing</FormLabel> 
              <FormControlLabel
                control={<Checkbox checked={allowExtraEmails} onChange={toggleOptIn}color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl required error={termsAndPrivacyError} component="fieldset">
              <FormLabel component="legend">Agreement</FormLabel> 
              <FormControlLabel
                control={<Checkbox checked={termsAndPrivacy} onChange={toggleTerms} color="primary" />}
                label="I have read and accept the General Subscription Terms & Conditions, the End User License and the Privacy Policy below"
              />
              <FormHelperText>{termsAndPrivacyHelp}</FormHelperText>
              </FormControl>
              <Typography variant="body1">
              <Link href="https://intheoffice.io/legal/terms" target="_blank">General Subscription Terms & Conditions</Link>, &nbsp;
                <Link href="https://intheoffice.io/legal/enduseragreement" target="_blank">End User Agreement</Link>
                 &nbsp;and&nbsp;
                <Link href="https://intheoffice.io/legal/privacy" target="_blank">Privacy Policy</Link>
              </Typography>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href={ROUTES.SIGN_IN} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
    </React.Fragment>
  );
}



const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);
 

 
export default SignUpForm;

