import React from 'react';
 
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  h1 : {
    marginTop: theme.spacing(4),
    fontSize: "xxx-large",
  },
  h2 : {
    marginTop: theme.spacing(2),
    fontSize: "xx-large",
  },
  body : {
    margin: theme.spacing(2, 0),
  },
}));

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const VerificationSent = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography  className={classes.h1} component="h1" variant="h1">Welcome to intheOffice</Typography>
    <Typography className={classes.h2} variant="h2">Verify your E-mail</Typography>
      <Typography className={classes.body} variant="body1">
      We have sent you an E-Mail to verify your new intheOffice account. <br />
      Please check you E-Mails (Spam folder included) for a confirmation E-Mail. <br />
      If you don't receive one in the next few minutes, click on the button below to resend.
      </Typography>
      </React.Fragment>
  );
}
const VerificationResent = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography  className={classes.h1} component="h1" variant="h1">Welcome to intheOffice</Typography>
    <Typography className={classes.h2} variant="h2">E-Mail Verification Resent</Typography>
      <Typography className={classes.body} variant="body1">
      Please check you E-Mails (Spam folder included) for a confirmation E-Mail. <br />
      Refresh this page once you confirmed your E-Mail.
      </Typography>
      </React.Fragment>
  );
}

const withEmailVerification = (Component) => {
  
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };
    render() {
      const { classes } = this.props;
      return (
        <AuthUserContext.Consumer>
          {authUser => 
            needsEmailVerification(authUser) ? (
              <div style={{marginTop:'40px'}} >
                <Container component="main" maxWidth="md">
                <div>
                
                {this.state.isSent ? (
                  <VerificationResent />
                ) : (
                <VerificationSent />
                )}

                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  //className={classes.submit}
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                >
                  Resend confirmation E-Mail
                </Button>
                </div>
                </Container>
              </div>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }
 
return withFirebase(WithEmailVerification);
};

export default withEmailVerification;


