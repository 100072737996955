import React, {useEffect, useState} from 'react';
import { withFirebase } from '../Firebase';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Navigation from '../AppBar';
import Single from '../Views/single';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
 
}));



  
 const Main = (props) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('authUser'));
  const [view, setView] = useState({format: 'single', integration: null, level:'root', id: null, integration2: null, level2: 'root', id2: null});
  const [format, setFormat] = useState('single');
  const [grid, setGrid] = useState(12);
  const [trelloExists, setTrelloExists] = useState(user.trello);

  useEffect(() =>{
    if(user.trello !== true){
      setTrelloExists(false);
    } 
    props.firebase.lastView(user.uid).get().then(result=>{
      if(result.exists){

      } else if (result.exists === false && trelloExists) {
        var viewAdjust = view;
        viewAdjust.integration = 'trello';
        setView(viewAdjust);
      }
    }).catch((error)=>{console.log(error, 'error')});
  },[user, view]);

  function toggleView(){
    switch (format){
      case 'double': {
        setFormat('single');
        setGrid(12);
        break;
      } 
      default: {
        setFormat('double');
        setGrid(6);
        break;
      } 
    }
  }


  return (
    <React.Fragment>
      <Navigation format={format} setView={setView} toggleView={toggleView} />
      <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={grid}>
        <Single trelloExists={trelloExists} view={view} />
        </Grid>
        {format === 'double' ?
        <Grid item xs={6}>
          <Single trelloExists={trelloExists} view={view} />
        </Grid>
        : ""}
        </Grid>
        
      </Container>
    </React.Fragment>


  );
}

export default withFirebase(Main);
